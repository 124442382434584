.black-button{
  color: white;
  background-color: black;
  padding: 10px;
  width: 200px;
  font-weight: 900;
  border: none;
  box-shadow: 3px 4px 6px 0px rgb(0 0 0);
  cursor: pointer;
  font-size: larger;
}
@media (max-width: 730px){
  .black-button{
    color: white;
    background-color: black;
    padding: 10px;
    width: 100px;
    font-weight: 900;
    border: none;
    box-shadow: 3px 4px 6px 0px rgb(0 0 0);
    cursor: pointer;
    font-size: smaller;
  }
}