.call-now__container{
  height: 300px;
  background-color: #23345f ;  
  /* background-image: URL("https://res.cloudinary.com/bobalobbadingdong/image/upload/v1670019254/BalonekDDSPC/Hero_backdroup_t8iceh.png"); */
  display: flex;
  justify-content: space-between;
}
.call-now__left{
width: 70%;
padding: 3%;
display: flex;
flex-direction: column;
justify-content: space-around;
color: aliceblue;
}
.call-now__right{
width: 20%;
padding: 3%;
}
.call-now-img{
  max-height: 100%;
    max-width: 100%;
}

@media (max-width:800px){
  .call-now__container{
    flex-direction: column;
    min-height: 400px;
    text-align: -webkit-center;
    justify-content: center;
  }
  .call-now__left{
    width: 98%;
    padding: 1%;
    }
    .call-now__right{
      display: none;
      }
      .call-now-img{
        width: 80%;
      }
}