.facebook-section{
  min-height: 500px;
  width: 100vw;
  background-color: #23345f ;
  /* background-image: URL("https://res.cloudinary.com/bobalobbadingdong/image/upload/v1670019254/BalonekDDSPC/Hero_backdroup_t8iceh.png"); */
  display: flex;
  justify-content: space-around;
  align-items: center;

}
@media (max-width:800px){
  .facebook-section {
    flex-direction:column-reverse;
    padding-top: 50px;
  }
}