@media (max-width: 420px) {
  .App {
    text-align: center;
  }
}
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}