.hero-banner__container{
  height: 300px;
  width: 100vw;
  background-color: #23345f ;
  /* background-image: URL("https://res.cloudinary.com/bobalobbadingdong/image/upload/v1670019254/BalonekDDSPC/Hero_backdroup_t8iceh.png"); */
  display: flex;
  justify-content: space-around;
  color: aliceblue;
}
.hero-banner__backdrop{
  position: absolute;
  top: 0;
  width: 100vw;
  height: 300px;
}
.hero-banner__logo{
  height: 150px;
  position: relative;
  top: 20px;
}
.hero-banner__cutout{
  /* height: 500px; */
  position: relative;
}
.hero-banner__tagline{
  color: white;
  position: relative;
  top: 20px;
  width: 150px;
}
.hero-banner__right-side__container{
  display: flex;
  flex-direction: column;
  position: relative;
  top: 20%;
}
.hero-banner__motto-second-line{
  
  margin-top: -20px;
}
.hero-banner__motto-third-line{
  position: relative;
  bottom: 20px;
}
.hero-banner__button-holder{
  
}

@media (max-width: 730px){
  .hero-banner__cutout{
    height: 300px;
    position: relative;
  }
  .hero-banner__logo{
    height: 100px;
    position: relative;
    top: 20px;
    left: 70px;
  }
  .hero-banner__tagline{
    color: white;
    position: relative;
    top: 20px;
    left: 80px;
    font-size: small;
  }
  .hero-banner__right-side__container h1{
    font-size: medium;
  }
  .hero-banner__right-side__container{
    display: flex;
    flex-direction: column;
    position: relative;
    top: 10%;
    right: 100px;
    width: 100px;
    padding-left: 70px;
  }
  .hero-banner__motto-second-line{
    
    margin-top: -10px;
  }
  .hero-banner__motto-third-line{
    position: relative;
    bottom: 10px;
    font-size:x-small
  }
  .hero-banner__button-holder{
    position: relative;
    top: 80px;
  }
}