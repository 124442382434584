.hours-map__container{
display: flex;
width: 100%;
background-color: black;
color:white ;
padding-top: 50px;
padding-bottom: 50px;
}
.hours{
width:50%;
display: flex;
justify-content: center;
}
.hours-box{
  /* width: 80%; */
  padding: 5%;
  /* background-color: blue; */
  /* border: 2px solid black; */
}
.map{
  width:50%;
display: flex;
justify-content: center;
}
.map-box{
   width: 80%;
  /* border: 2px solid black; */
  padding: 5%;
  /* background-color: red; */
}
.centered-card {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 800px){
  .hours-map__container{
    flex-direction: column;
    align-items: center;
    }
    .hours{
      width: 100%;
    }
      .map{
        width: 100%;
      }
}