.insurances__container {
  /* border: solid black; */
  /* border-width: 2px; */
  padding: 10px;
  /* box-shadow: 1px 2px 1px 1px black; */
  padding-bottom: 15px;
  /* background-color: white; */
  color: aliceblue;
}

